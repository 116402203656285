<script lang="ts">
interface ArticleRatingData {
    id: string;
    data: { [key: string]: number };
}

const STORAGE_KEY = 'energysage-article-user-actions';

export default defineComponent({
    name: 'ArticleUserActions',
    props: {
        article: {
            type: Object as PropType<ArticlePage001 | LocalArticlePage001>,
            required: true,
        },
        seoData: {
            type: Object as PropType<SeoDataInterface>,
            required: true,
        },
        globalArticleCopy: {
            type: Object as PropType<GlobalArticleCopyInterface>,
            required: true,
        },
    },
    setup() {
        const route = useRoute();
        const config = useRuntimeConfig();
        return { route, config };
    },
    data() {
        return {
            vote: 0,
            visitId: '',
            articleVotes: {} as { [key: string]: number },
        };
    },
    computed: {
        articleTitle() {
            if (this.article?.seo?.title) {
                return this.article.seo.title;
            }
            if (this.article?.component === 'ArticlePage001') {
                const article = this.article as ArticlePage001;
                return article.articleEeat?.[0]?.header || '';
            }
            if (this.article?.component === 'LocalArticlePage001') {
                const article = this.article as LocalArticlePage001;
                return article.eeat?.[0]?.heading || '';
            }
            return '';
        },
        articleCategory() {
            if (this.article?.component === 'ArticlePage001') {
                const article = this.article as ArticlePage001;
                return article.articleEeat?.[0]?.category;
            }
            return undefined;
        },
    },
    mounted() {
        this.updateFromLocalStorage();
    },
    methods: {
        updateFromLocalStorage() {
            // Read the data
            const data = localStorage.getItem(STORAGE_KEY);
            if (data?.length) {
                let parsedData: ArticleRatingData;
                try {
                    parsedData = JSON.parse(data);
                } catch {
                    console.error(`Could not parse value: ${data}`);
                    return;
                }
                // Get previous `visitId`, or regenerate if not found
                // If this fails, user will look like a new user to Hubspot
                this.visitId = parsedData.id || this.generateVisitId();
                // Get previous `articleVotes`, or regenerate if not found
                // If this fails, only consequence is previous vote will not diplay
                this.articleVotes = parsedData.data || ({} as { [key: string]: number });

                // If `vote` is set for the current article, set it for the component
                const storedVote = this.articleVotes[`${this.article._uid}`];
                if ([1, 2].includes(storedVote)) {
                    this.vote = storedVote;
                }
            }
        },
        writeToLocalStorage() {
            if (this.vote === 0) {
                // Delete vote from data
                // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
                delete this.articleVotes[`${this.article._uid}`];
            } else if (this.vote) {
                // Update `articleVotes` with the current data
                this.articleVotes[`${this.article._uid}`] = this.vote;
            }
            // If visitId doesn't exist, create it
            this.visitId = this.visitId || this.generateVisitId();

            // Write the data with `visitId` and `articleVotes`
            const data = JSON.stringify({ id: this.visitId, data: this.articleVotes });
            localStorage.setItem(STORAGE_KEY, data);
        },
        generateVisitId(): string {
            /* Generate a random string to identify a visit
             * This is to identify the source of form submissions in hubspot.
             * It will only work to the extent that if a user changes their vote on an article
             * while they still have this data stored, it will be theoretically possible to
             * deduplicate the data sent to Hubspot.
             *
             * If they clear the data or modify it to the point that it can't be reloaded by
             * `updateFromLocalStorage`, they will get a fresh visitId and Hubspot may think that
             * duplicate votes from them are actually from new, different user.
             */

            const length = 16;
            const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            const randomIndices = Array.from({ length }, () => Math.floor(Math.random() * characters.length));
            return randomIndices.map((index) => characters[index]).join('');
        },
        async submitHubspotForm(event: any) {
            // Store vote in component & local storage
            event.preventDefault();
            const value = Number(event.submitter.value);
            if (value === this.vote) {
                // Unset vote
                this.vote = 0;
            } else {
                this.vote = value;
            }
            this.writeToLocalStorage();

            // Create Hubspot payload
            const voteText = {
                0: 'none',
                1: 'helpful',
                2: 'not helpful',
            }[this.vote];
            // 'Article pages helpful/not helpful'
            const formID = '62f05f81-567e-4b34-a9cb-8da7fb686972';
            const fields = [
                { objectTypeId: '2-18509001', name: 'submitter_id', value: this.visitId },
                { objectTypeId: '2-18509001', name: 'id_label', value: 'visit ID' },
                { objectTypeId: '2-18509001', name: 'rating_label', value: voteText },
                { objectTypeId: '2-18509001', name: 'rating_code', value: this.vote },
                { objectTypeId: '2-18509001', name: 'max_rating', value: 2 },
                { objectTypeId: '2-18509001', name: 'subject_type', value: 'Article' },
                { objectTypeId: '2-18509001', name: 'subject_id', value: this.article._uid },
                { objectTypeId: '2-18509001', name: 'subject_label', value: this.articleTitle },
                { objectTypeId: '2-18509001', name: 'subject_url', value: window.location.pathname },
            ];

            if (this.config.public.DEPLOY_ENV === 'prod') {
                // Post form data to Hubspot
                try {
                    await postHubspotForm(formID, fields);
                } catch (e) {
                    console.error(e);
                }
            }
        },
    },
});
</script>

<template>
    <div class="ArticleUserActions mt-lg-100">
        <a
            class="d-flex mb-100"
            href="#">
            <icon-arrow-up class="mr-50" />
            {{ globalArticleCopy?.backToTop }}
        </a>
        <div class="block-dividers d-lg-flex justify-content-between text-black font-weight-bold font-size-75 py-100">
            <div class="d-flex align-items-center text-left mb-100 mb-lg-0">
                <span class="mr-100">
                    {{ globalArticleCopy?.wasPageHelpful }}
                </span>
                <form
                    class="d-flex justify-content-center"
                    novalidate
                    @submit.prevent="submitHubspotForm">
                    <button
                        class="vote-circle d-inline-flex border border-dark text-black rounded-circle mr-100"
                        :class="{ 'vote-selected': vote === 1 }"
                        type="submit"
                        value="1">
                        <span class="sr-only">{{ globalArticleCopy?.yes }}</span>
                        <icon-thumbs-up
                            class="vote-icon"
                            height="1rem"
                            width="1rem" />
                    </button>
                    <button
                        class="vote-circle d-inline-flex border border-dark text-black rounded-circle"
                        :class="{ 'vote-selected disabled': vote === 2 }"
                        type="submit"
                        value="2">
                        <span class="sr-only">{{ globalArticleCopy?.no }}</span>
                        <icon-thumbs-up
                            class="vote-icon inverted"
                            height="1rem"
                            width="1rem" />
                    </button>
                </form>
            </div>
            <div class="d-flex align-items-center text-left">
                <span class="mr-100">
                    {{ globalArticleCopy?.shareOnSocial }}
                </span>
                <social-links
                    class="d-flex justify-content-center"
                    :nuxt-route-path="route.path"
                    unique-id="actions"
                    :share-text="articleTitle"
                    :share-category="articleCategory"
                    :seo-data="seoData"
                    :copy-tooltip-success="globalArticleCopy?.linkCopySuccess" />
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
@use '@energysage/es-ds-styles/scss/variables';

.ArticleUserActions {
    .block-dividers {
        border-block: 2px solid variables.$gray-400;
    }

    .vote-circle {
        background-color: variables.$gray-200;
        padding: 0.4rem;

        &:hover,
        &:focus,
        &:active {
            background-color: variables.$gray-300;
        }

        &.vote-selected {
            background-color: variables.$cyan;

            &:hover,
            &:focus,
            &:active {
                background-color: variables.$cyan-600;
            }
        }
    }

    :deep(.vote-icon) {
        :first-of-type {
            // Override `fill="none"` in icon-thumbs-up
            fill: variables.$white;
        }
    }

    .inverted {
        rotate: 180deg;
    }

    :deep(.social-links-list__svg) {
        height: 31px !important;
        width: 31px !important;
    }
}
</style>
